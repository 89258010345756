<template>
  <modal :class-name="width" ref="modal">
    <component
      :is="tab"
      @navigateToAddAccount="navigateToAddAccount"
      @back="back"
      @confirmWithdraw="confirmWithdraw"
      @navigateToConfirmWithdraw="navigateToConfirmWithdraw"
      :amount="amount"
      :loading="loading"
    />
  </modal>

  <alert-modal ref="successModal">
    <template #title> Money Sent! </template>
    <template #subtitle>
      You’ll be sent an investment certificate once payment is confirmed.
    </template>
    <template #body>
      <router-link
        :to="{ name: 'Dashboard' }"
        class="mt-5 pt-3 btn btn-primary"
      >
        Go Home
      </router-link>
    </template>
  </alert-modal>
</template>
<script lang="ts">
import {
  defineComponent,
  defineAsyncComponent,
  ref,
  computed,
  toRefs,
  PropType,
  SetupContext,
} from 'vue';
import { withdrawInvestment } from '@/modules/requests';
import { useStore } from 'vuex';
import { InvestmentProp } from '@/types/Investment';

export default defineComponent({
  inheritAttrs: false,
  props: {
    amount: {
      type: [Number, String],
      required: true,
      validator: (amount: string | number) => amount > 0,
    },
    investment: {
      type: Object as PropType<InvestmentProp>,
      required: true,
    },
  },
  emits: ['onSuccess'],
  components: {
    accounts: defineAsyncComponent(() => import('./Accounts.vue')),
    'add-account': defineAsyncComponent(() => import('./AddAcount.vue')),
    'confirm-withdraw': defineAsyncComponent(
      () => import('./ConfirmWithdraw.vue'),
    ),
  },
  setup: (props, { emit }: SetupContext) => {
    const modal = ref<any>(null);
    const tab = ref<any>('accounts');

    const accountResource = ref<any>(null);
    const successModal = ref<any>(null);

    const loading = ref<boolean>(false);

    const { investment }: any = toRefs(props);

    const { state } = useStore();

    const width = computed(() => {
      if (tab.value === 'confirm-withdraw') return 'w-11/12 pt-10 sm:w-97';
      else return 'w-11/12 pt-10 sm:w-480';
    });

    const back = (): string => (tab.value = 'accounts');

    const navigateToAddAccount = (): string => (tab.value = 'add-account');

    const open = (): void => modal.value.open();

    const navigateToConfirmWithdraw = (data: any): void => {
      accountResource.value = { ...data };
      tab.value = 'confirm-withdraw';
    };

    const confirmWithdraw = async (): Promise<void> => {
      const bankCode = state?.utils?.banks?.find(
        (bank: any) => bank?.name === accountResource.value.bank_name,
      )?.code;

      loading.value = true;

      await withdrawInvestment(investment.value?.id, {
        ...accountResource.value,
        bank_code: bankCode,
      })
        .then(() => {
          modal.value.close();
          successModal.value.open();
          emit('onSuccess');
        })
        .catch((error) => console.log(error))
        .finally(() => (loading.value = false));
    };

    return {
      modal,
      tab,
      open,
      navigateToAddAccount,
      back,
      width,
      confirmWithdraw,
      navigateToConfirmWithdraw,
      successModal,
      loading,
    };
  },
});
</script>
